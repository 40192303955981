import type { HeadlineConfig } from '@/features/headline-v1/headline-variants/headline-title-variants';

import { headlineTitleVariants } from '@/features/headline-v1/headline-variants/headline-title-variants';

// Used on Desktop S2 for spot text title
const bold: HeadlineConfig = {
    ...headlineTitleVariants['bold'],
    fontSize: 18,
    marginBottom: 0,
};

// Used on Desktop S2 for spot text subtitle (but as headline title)
const boldS: HeadlineConfig = {
    ...headlineTitleVariants['bold-s'],
    fontSize: 15,
    marginBottom: -5,
};

export const headlineDesktopTitleVariants = {
    ...headlineTitleVariants,
    bold,
    'bold-s': boldS,
};

import type { HeadlineConfig } from '@/features/headline-v1/headline-variants/headline-title-variants';

const defaultConfig: HeadlineConfig = {
    center: false,
    fontWeight: 'normal',
    marginBottom: 0,
};

// Unused but possible
const bold: HeadlineConfig = {
    ...defaultConfig,
    fontSize: 18,
    fontWeight: 'bold',
};

// Used on Desktop S1
const size18: HeadlineConfig = {
    ...defaultConfig,
    fontSize: 18,
};

export const headlineSubtitleVariants = {
    bold,
    default: defaultConfig,
    'size-18': size18,
};

import color from '@/core/features/styles/color';

export type HeadlineConfig = {
    center: boolean;
    color?: string;
    fontSize?: number;
    fontWeight: 'bold' | 'normal';
    marginBottom: number;
    marginTop?: number;
};

const defaultConfig: HeadlineConfig = {
    center: false,
    fontWeight: 'normal',
    marginBottom: 0,
};

// Used on Desktop S1
const size24: HeadlineConfig = {
    center: false,
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
};

// Used on Desktop S0
const centerBold: HeadlineConfig = {
    center: true,
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 0,
};

// Used on Mobile S1
const centerMutedS: HeadlineConfig = {
    center: true,
    color: color('doveGray'),
    fontSize: 14,
    fontWeight: 'normal',
    marginBottom: 5,
};

// Used on Mobile S2 for spot text title
const bold: HeadlineConfig = {
    center: false,
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 0,
};

// Used on Mobile S2 for spot text subtitle (but as headline title)
const boldS: HeadlineConfig = {
    center: false,
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: -5,
};

export const headlineTitleVariants = {
    bold,
    'bold-s': boldS,
    'center-bold': centerBold,
    'center-muted-s': centerMutedS,
    default: defaultConfig,
    'size-24': size24,
};
